const template = document.createElement('template');
template.innerHTML = `
<footer id="footer" class="container-fluid">
    <div class="container text-light">
        <div class="row">
            <div class="col first">
                <div class="icon medium logo-w"></div>
            </div>
            <div class="col">
                <ul>
                    <li><a href="ueber-uns.html">Über uns</a></li>
                    <li><a href="faq.html">FAQ</a></li>
                    <li><a href="manuelle-anbindung.html">Manuelle&nbsp;Anbindung</a></li>
                </ul>
            </div>
            <div class="col">
                <ul>
                    <li><a href="versandkosten.html">Versandkosten</a></li>
                    <li><a href="https://katalog.dropiverse.de/" target="_blank">Produktkatalog</a></li>
                </ul>
            </div>
            <div class="col">
                <ul>
                    <li><a href="impressum.html">Impressum</a></li>
                    <li><a href="agb.html">AGB</a></li>
                    <li><a href="datenschutz.html">Datenschutz</a></li>
                </ul>
            </div>
            <div class="col last">
                <a href="/" class="logo"></a>
                <a href="/" class="website-link">www.dropiverse.de</a>
                <div class="socials">
                    <a href="https://www.youtube.com/channel/UC_eqaxOQMM_i7bcYNZeyGrQ" target="_blank"><div class="icon xsmall youtube"></div></a>
                    <a href="https://www.instagram.com/dropiverse/" target="_blank"><div class="icon xsmall instagram"></div></a>
                    <!--<a href="https://facebook.com/" target="_blank"><div class="icon xsmall facebook"></div></a>-->
                    <!--<a href="https://twitter.com/" target="_blank"><div class="icon xsmall twitter"></div></a>-->
                </div>
            </div>
        </div>
    </div>
</footer>`;

class Footer extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.innerHTML = template.innerHTML;
    }
}
window.customElements.define('wc-footer', Footer);